/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

import { useEffect } from "react";
import { Link } from "react-router-dom";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// side components
import SideHeader from "./components/SideHeader/SideHeader";
import SideLink from "./components/SideLink/SideLink";

const Sidebar = () => {
  const { languageState } = useLanguage();
  const { setRouteState } = useRoute();

  useEffect(() => {
    setRouteState({ type: "set", to: 0 });
  }, []);

  return (
    <nav id="sidebar" className="dashboard-sidebar js-sidebar">
      <SitoContainer ignoreDefault className="dashboard-sidebar-content js-simplebar">
        <Link className="dashboard-sidebar-brand" to="/">
          <span className="dashboard-align-middle">{languageState.texts.Title}</span>
        </Link>

        <ul className="dashboard-sidebar-nav">
          {languageState.texts.Dashboard.Sidebar.Links.map((item) => (
            <SitoContainer ignoreDefault key={item.label}>
              {item.type === "header" && <SideHeader text={item.label} />}
              {item.type === "link" && <SideLink content={item} />}
            </SitoContainer>
          ))}
        </ul>
      </SitoContainer>
    </nav>
  );
};

export default Sidebar;
