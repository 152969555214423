/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { userList, getMe, getAgents } from "services/get";
import { blockUser } from "services/post";

// functions
import { isAdmin } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

const Block = () => {
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [localList, setLocalList] = useState({});
  const [loading, setLoading] = useState(true);

  const [userToBlock, setUserToBlock] = useState(0);
  const [ok, setOk] = useState(true);
  const [reason, setReason] = useState("");

  const handleReason = (e) => {
    const { value } = e.target;
    setReason(value);
  };

  const changeUserToBlock = (e) => setUserToBlock(Number(e.target.value));

  const fetch = async () => {
    setLoading(true);
    let response;
    if (isAdmin()) response = await userList();
    else response = await getAgents();
    if (response.status === 200) {
      const { data } = await response.data;
      if (data.length) {
        let parsedUsers = data;
        if (!isAdmin()) {
          const { inmobiliaria_id } = await getMe();
          parsedUsers = [];
          data.forEach((item) => {
            if (inmobiliaria_id === item.inmobiliaria_id) parsedUsers.push(item);
          });
        }
        setLocalList(parsedUsers);
      }
    } else {
      setLocalList(-1);
      setNotificationState({
        type: "set",
        message: languageState.texts.Errors.NotConnected,
        ntype: "error",
      });
    }
    setLoading(false);
  };

  const validate = () => {
    setOk(true);
  };

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.ReasonRequired,
          });
      }
    }
  };

  const retry = () => {
    fetch();
  };

  const localBlockUser = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (ok) {
      try {
        const response = await blockUser(userToBlock, reason);
        if (response.status === 200) {
          setNotificationState({
            type: "set",
            message: languageState.texts.Messages.UserBlockedSuccessful,
            ntype: "success",
          });
          retry();
        }
      } catch (err) {
        const { message } = err;
        if (message.indexOf("401") > -1) {
          setNotificationState({
            type: "set",
            message: languageState.texts.Errors.InvalidUser,
            ntype: "error",
          });
        }
      }
    }
    setLoading(true);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <SitoContainer
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {!loading && localList.length > 0 && (
        <form onSubmit={localBlockUser} className={css({ width: "100%" })}>
          <SitoContainer ignoreDefault className="form-group" sx={{ width: "100%" }}>
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.User.Block.Title}
            </h3>
            <label htmlFor="users">{languageState.texts.Dashboard.User.Block.Label}</label>
            <select
              className="form-control"
              id="users"
              value={userToBlock}
              onChange={changeUserToBlock}
            >
              {localList.map((jtem) => (
                <option key={jtem.id} value={jtem.id}>
                  {jtem.nombre}
                </option>
              ))}
            </select>
            <SitoContainer ignoreDefault className="form-group" sx={{ marginTop: "20px" }}>
              <label htmlFor="reason">
                {languageState.texts.Dashboard.User.Block.Reason.label}
              </label>
              <input
                placeholder={languageState.texts.Dashboard.User.Block.Reason.placeholder}
                type="text"
                name="reason"
                id="reason"
                required
                value={reason}
                onChange={handleReason}
                onInput={validate}
                onInvalid={invalidate}
              />
            </SitoContainer>
            <SitoContainer ignoreDefault sx={{ marginTop: "20px" }}>
              <button type="submit" className="butn">
                {languageState.texts.Dashboard.Submit.Block}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
      {!loading && !localList.length && localList !== -1 && <Empty />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

export default Block;
