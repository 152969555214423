/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import {
  userLogged,
  createCookie,
  scrollTo,
  areValidatingEmail,
  logUser,
  removeValidatingCookie,
  isAdmin,
} from "utils/functions";

// services
import { signIn } from "services/post";

import config from "config";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit } = useForm();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();
  const [loading, setLoading] = useState(false);

  const [ok, setOk] = useState(1);

  const [remember, setRemember] = useState(false);

  const toggleRemember = () => {
    setRemember(!remember);
  };

  const localSignIn = async (d) => {
    setLoading(true);
    const { user, password } = d;
    try {
      const response = await signIn({ user, password });
      if (response.status === 200) {
        logUser(remember, user);
        createCookie(config.jwtKey, response.data.expire, response.data.token);
        setNotificationState({
          type: "set",
          message: languageState.texts.Messages.LoginSuccessful,
          ntype: "success",
        });
        setTimeout(() => {
          if (isAdmin()) navigate("/dashboard");
          else navigate("/profile");
        }, 100);
      }

      if (response.error) {
        setLoading(false);
        const { error } = response;
        let message;
        console.log(response);
        if (error.indexOf("422") > -1) message = languageState.texts.Errors.Wrong;
        else if (error.indexOf("Error: Network Error") > -1)
          message = languageState.texts.Errors.NotConnected;
        else message = languageState.texts.Errors.SomeWrong;
        setNotificationState({
          type: "set",
          ntype: "error",
          message,
        });
      }
    } catch (err) {
      console.log(err);
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.SomeWrong,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = location.search.substring(1);
    if (queryParams === "verified" || queryParams.indexOf("verified") > -1)
      removeValidatingCookie();
    if (userLogged() && !areValidatingEmail()) {
      if (isAdmin()) navigate("/dashboard");
      else navigate("/profile");
    }
    const loginForm = document.getElementById("login-form");
    scrollTo((loginForm.offsetHeight - loginForm.offsetTop) / 2);
    setRouteState({ type: "set", to: 2, page: languageState.texts.Links[2].label });
  }, []);

  const validate = () => {
    setOk(true);
  };

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "user":
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.NameRequired,
          });
        case "password":
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.NoEmptyPassword,
          });
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.TermsNdConditionRequired,
          });
      }
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[2].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul>
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <li>
                  <Link to="/login" disabled>
                    {languageState.texts.Links[2].label}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-7 center-col">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-all xs-padding-25px-all border-radius-3"
              >
                <h3 className="text-center font-size30">{languageState.texts.Links[2].label}</h3>
                <form
                  id="login-form"
                  method="post"
                  action="#!"
                  onSubmit={handleSubmit(localSignIn)}
                >
                  <SitoContainer ignoreDefault className="row">
                    {languageState.texts.SignIn.inputs
                      .filter((item) => {
                        if (item.login) return item;
                        return null;
                      })
                      .map((item) => (
                        <SitoContainer key={item.id} ignoreDefault className="col-md-12">
                          <SitoContainer ignoreDefault className="form-group">
                            <label htmlFor={item.id}>{item.label}</label>
                            <input
                              placeholder={item.placeholder}
                              type={item.type}
                              name={item.id}
                              id={item.id}
                              required={item.required}
                              onInput={validate}
                              onInvalid={invalidate}
                              {...register(item.id)}
                            />
                          </SitoContainer>
                        </SitoContainer>
                      ))}
                    <SitoContainer ignoreDefault className="col-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="remember" className="no-margin-bottom">
                          <input
                            id="remember"
                            onChange={toggleRemember}
                            type="checkbox"
                            className="min-height-auto"
                          />
                          {languageState.texts.SignIn.remember.label}
                        </label>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>

                  <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.SignIn.titleIn}
                      </button>
                    </SitoContainer>
                    <SitoContainer
                      ignoreDefault
                      className="col-md-6 text-right xs-text-left xs-margin-20px-top"
                    >
                      <Link to="/login/recovery">
                        {languageState.texts.SignIn.recovery.message}
                      </Link>{" "}
                      {languageState.texts.SignIn.or}{" "}
                      <Link to="/login/register" style={{ textDecoration: "underline" }}>
                        {languageState.texts.SignIn.signUp.link}
                      </Link>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Login;
