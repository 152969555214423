/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// layouts
import Hero from "layouts/Hero/Hero";
import Section from "layouts/Section/Section";

// services
import { contact } from "services/post";

// utils
import { scrollTo } from "utils/functions";

// sections
import MapSection from "./Sections/Map/Map";

const Contact = () => {
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [ok, setOk] = useState(true);

  useEffect(() => {
    setRouteState({ type: "set", to: 5, page: languageState.texts.Links[4].label });
    const contactForm = document.getElementById("contact-form");
    scrollTo((contactForm.offsetHeight - contactForm.offsetTop) / 2);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "name":
        return setName(value);
      case "email":
        return setEmail(value);
      case "subject":
        return setSubject(value);
      default:
        return setMessage(value);
    }
  };

  const localContact = async (e) => {
    e.preventDefault();
    try {
      const response = await contact({ name, email, subject, message });
      if (response.status === 200) {
        // hola
      }
      if (response.error) {
        console.log(response.error);
        setNotificationState({
          type: "set",
          ntype: "error",
          message:
            response.error.indexOf("Error: Network Error") > -1
              ? languageState.texts.Errors.NotConnected
              : languageState.texts.Errors.Wrong,
        });
      }
    } catch (err) {
      console.log(err);
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.Wrong,
      });
    }
  };

  const validate = () => {
    setOk(true);
  };

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "name":
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.NameRequired,
          });
        case "subject":
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.SubjectRequired,
          });
        case "message":
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.MessageRequired,
          });
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.EmailRequired,
          });
      }
    }
  };

  return (
    <>
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[3].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul>
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <li>
                  <Link to="/login" disabled>
                    {languageState.texts.Links[3].label}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-8 sm-margin-30px-bottom">
              <SitoContainer ignoreDefault className="padding-50px-right sm-no-padding-right">
                <form id="contact-form" method="post" action="#!" onSubmit={localContact}>
                  <SitoContainer ignoreDefault className="row">
                    <SitoContainer ignoreDefault className="col-lg-6">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="name">
                          {languageState.texts.Contact.inputs.name.label}
                        </label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          required
                          value={name}
                          onChange={handleChange}
                          onInput={validate}
                          onInvalid={invalidate}
                          placeholder={languageState.texts.Contact.inputs.name.placeholder}
                        />
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-lg-6">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="email">
                          {languageState.texts.Contact.inputs.email.label}
                        </label>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          required
                          value={email}
                          onChange={handleChange}
                          onInput={validate}
                          onInvalid={invalidate}
                          placeholder={languageState.texts.Contact.inputs.email.placeholder}
                        />
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-lg-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="subject">
                          {languageState.texts.Contact.inputs.subject.label}
                        </label>
                        <input
                          id="subject"
                          type="text"
                          name="subject"
                          required
                          value={subject}
                          onChange={handleChange}
                          onInput={validate}
                          onInvalid={invalidate}
                          placeholder={languageState.texts.Contact.inputs.subject.placeholder}
                        />
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-lg-12 margin-20px-bottom">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="message">
                          {languageState.texts.Contact.inputs.message.label}
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          className="form-control line-height-70"
                          required
                          value={message}
                          onChange={handleChange}
                          onInput={validate}
                          onInvalid={invalidate}
                          placeholder={languageState.texts.Contact.inputs.message.placeholder}
                        ></textarea>
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="col-lg-12">
                      <SitoContainer ignoreDefault className="Send">
                        <button type="submit" className="butn">
                          {languageState.texts.Contact.Send}
                        </button>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-4">
              <SitoContainer ignoreDefault>
                <h4>Contact Details</h4>
                <p className="contact-title-text">
                  Get in touch with us for any questions about our projects.
                </p>
              </SitoContainer>
              <SitoContainer ignoreDefault className="d-flex align-items-center margin-20px-bottom">
                <SitoContainer ignoreDefault>
                  <span className="ti-home font-size38 xs-font-size34 text-theme-color"></span>
                </SitoContainer>
                <SitoContainer ignoreDefault className="margin-30px-left">
                  <p className="no-margin-bottom text-extra-dark-gray font-weight-600">Reach Us</p>
                  <p className="no-margin-bottom width-90">
                    74 Norava Street 542B, Great North Town 51 MT.
                  </p>
                </SitoContainer>
              </SitoContainer>
              <SitoContainer ignoreDefault className="d-flex align-items-center margin-20px-bottom">
                <SitoContainer ignoreDefault>
                  <span className="ti-email font-size38 xs-font-size34 text-theme-color"></span>
                </SitoContainer>
                <SitoContainer ignoreDefault className="margin-30px-left">
                  <p className="no-margin-bottom text-extra-dark-gray font-weight-600">
                    Drop A Mail
                  </p>
                  <p className="no-margin-bottom width-90">
                    your@name.gmail.com
                    <br />
                    example@gmail.com
                  </p>
                </SitoContainer>
              </SitoContainer>
              <SitoContainer ignoreDefault className="d-flex align-items-center">
                <SitoContainer ignoreDefault>
                  <span className="ti-mobile font-size38 xs-font-size34 text-theme-color"></span>
                </SitoContainer>
                <SitoContainer ignoreDefault className="margin-30px-left">
                  <p className="no-margin-bottom text-extra-dark-gray font-weight-600">Call Us</p>
                  <p className="no-margin-bottom">
                    (+44) 123 456 789
                    <br />
                    +91 235 548 7548
                  </p>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>

      <MapSection />
    </>
  );
};

export default Contact;
