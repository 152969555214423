/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/function-component-definition */

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

// image
import { Link } from "react-router-dom";

const Footer = () => {
  const { languageState } = useLanguage();

  return (
    <footer className="dashboard-footer">
      <SitoContainer ignoreDefault className="dashboard-container-fluid">
        <SitoContainer ignoreDefault className="dashboard-row dashboard-text-muted">
          <SitoContainer ignoreDefault className="dashboard-col-6 dashboard-text-start">
            <p className="dashboard-mb-0">
              <Link className="dashboard-text-muted" to="/" target="_blank" rel="noreferrer">
                <strong>{languageState.texts.Title}</strong>
              </Link>
              &copy;
            </p>
          </SitoContainer>
          <SitoContainer ignoreDefault className="dashboard-col-6 dashboard-text-end">
            <ul className="dashboard-list-inline">
              {languageState.texts.Dashboard.Footer.Links.map((item) => (
                <li key={item.label} className="dashboard-list-inline-item">
                  <Link
                    className="dashboard-text-muted"
                    to={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </footer>
  );
};

export default Footer;
