/* eslint-disable react/function-component-definition */
import { Link } from "react-router-dom";

// section
import Section from "layouts/Section/Section";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

// image
import comingsong from "img/reals/bg/comingsoon-bg.webp";

const NotFound = () => {
  const { languageState } = useLanguage();

  return (
    <Section
      className="full-screen bg-img text-center cover-background text-white no-padding"
      data-overlay-dark="7"
      background={comingsong}
    >
      <SitoContainer
        sx={{ width: "100vw", height: "100vh", background: "#2222228a", position: "fixed" }}
      />
      <SitoContainer
        ignoreDefault
        className="container h-100"
        sx={{ height: "100vh !important", zIndex: 1 }}
      >
        <SitoContainer
          ignoreDefault
          className="display-table w-100 h-100 width-55 sm-width-90 padding-four-all xs-padding-eight-tb"
        >
          <SitoContainer ignoreDefault className="display-table-cell vertical-align-middle">
            <SitoContainer ignoreDefault className="row page-container">
              <SitoContainer ignoreDefault className="col-12">
                <p className="font-size130 alt-font font-weight-300 title">404</p>
                <h2 className="text-white font-size40 xs-font-size22 margin-30px-bottom font-weight-400">
                  {languageState.texts.NotFound.Title}
                </h2>

                <p className="font-size15 font-weight-200 width-55 xs-width-95 center-col margin-30px-bottom">
                  {languageState.texts.NotFound.Description}
                </p>

                <Link to="/" className="butn margin-10px-right margin-10px-bottom">
                  {languageState.texts.NotFound.Return}
                </Link>
                <Link to="/contact-us" className="butn margin-10px-bottom">
                  {languageState.texts.NotFound.Contact}
                </Link>
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="row margin-50px-top sm-margin-25px-top">
              <SitoContainer ignoreDefault className="col-12">
                &copy; 2020 Adventure{" "}
                <Link to="/privacy-policy" className="text-white">
                  Privacy Policy
                </Link>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

export default NotFound;
