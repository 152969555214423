/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import { passwordValidation, userLogged, scrollTo } from "utils/functions";

// get
import { getMe } from "services/get";
import { saveProfile } from "services/post";

// images
import userPhoto from "img/content/myprofile-1.jpg";
import { InputsEnum } from "utils/Enum";
import Sidebar from "./Sections/Sidebar/Sidebar";

const Me = () => {
  const navigate = useNavigate();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();
  const { register, handleSubmit, setValue, getValues } = useForm();

  const [currentSidebarLink, setSidebarLink] = useState(0);

  const changeSidebarLink = (newLink) => setSidebarLink(newLink);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await getMe();
      const meData = await response.data;
      setValue("name", meData.nombre);
      setValue("lastname", meData.apellidos);
      setValue("email", meData.email);
      console.log(meData);
      setUserData(meData);
    } catch (err) {
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.SomeWrong,
      });
    }
    setLoading(false);
  };

  const retry = () => {
    fetch();
  };

  const localSaveProfile = async (d) => {
    setLoading(true);
    const { password, rpassword } = d;
    if (password === rpassword) {
      try {
        const { name, lastname, email } = d;
        const data = { nombre: name, apellidos: lastname, email };
        if (password.length > 0 && rpassword.length > 0) {
          data.password_hash = password;
          data.password_hash_repeat = rpassword;
        }
        const response = await saveProfile(data);
        if (response.status === 200) {
          setNotificationState({
            type: "set",
            message: languageState.texts.Messages.UserModifiedSuccessful,
            ntype: "success",
          });
          retry();
        }
      } catch (err) {
        setNotificationState({
          type: "set",
          ntype: "error",
          message: languageState.texts.Errors.SomeWrong,
        });
      }
    } else
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.DifferentPassword,
      });

    setLoading(false);
  };

  const validate = () => {
    setOk(true);
  };

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    const { user, email, password, rpassword } = getValues();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "tuition":
        case "email":
          message = languageState.texts.Errors.InvalidEmail;
          if (email.length === 0) message = languageState.texts.Errors.EmailRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "user":
          message = languageState.texts.Errors.NameRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            user
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.TermsNdConditionRequired,
          });
      }
    }
  };

  useEffect(() => {
    if (!userLogged()) navigate("/");
    scrollTo();
    setRouteState({ type: "set", to: 6, page: languageState.texts.Links[6].label });
    retry();
  }, []);

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[6].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul>
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <li>
                  <Link to="/profile">{languageState.texts.Links[6].label}</Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>

      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-4 sm-margin-30px-bottom">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-tb padding-30px-lr border-radius-3"
              >
                <SitoContainer ignoreDefault className="text-center margin-30px-bottom">
                  <SitoImage
                    src={userPhoto}
                    alt=""
                    sx={{
                      height: "auto",
                      width: { md: "70%", sm: "25%", xs: "50%" },
                      marginBottom: "20px",
                      borderRadius: "50%",
                    }}
                  />

                  <h4 className="font-size19 no-margin-bottom">{userData.nombre}</h4>
                  <span className="font-size16">{userData.apellidos}</span>
                </SitoContainer>

                <Sidebar
                  onAction={changeSidebarLink}
                  currentLink={currentSidebarLink}
                  state={userData.inmobiliaria}
                />
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-8">
              <SitoContainer ignoreDefault className="padding-20px-left sm-no-padding-left">
                <SitoContainer
                  ignoreDefault
                  className="theme-shadow padding-40px-all sm-padding-30px-all xs-padding-20px-all border-radius-3"
                >
                  <h4 className="font-size24">{languageState.texts.Links[6].label}</h4>
                  <form className="margin-20px-bottom" onSubmit={handleSubmit(localSaveProfile)}>
                    <SitoContainer ignoreDefault className="row">
                      <SitoContainer ignoreDefault className="col-lg-6">
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor="name">
                            {languageState.texts.SignIn.inputs[InputsEnum.Name].label}
                          </label>
                          <input
                            type="text"
                            id="name"
                            {...register("name")}
                            onInput={validate}
                            onInvalid={invalidate}
                          />
                        </SitoContainer>
                      </SitoContainer>
                      <SitoContainer ignoreDefault className="col-lg-6">
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor="lastname">
                            {languageState.texts.SignIn.inputs[InputsEnum.LastName].label}
                          </label>
                          <input
                            type="text"
                            id="lastname"
                            {...register("lastname")}
                            onInput={validate}
                            onInvalid={invalidate}
                          />
                        </SitoContainer>
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="row">
                      <SitoContainer ignoreDefault className="col-lg-12">
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor="email">
                            {languageState.texts.SignIn.inputs[InputsEnum.Email].label}
                          </label>
                          <input
                            type="email"
                            id="email"
                            {...register("email")}
                            onInput={validate}
                            onInvalid={invalidate}
                          />
                        </SitoContainer>
                      </SitoContainer>
                    </SitoContainer>
                    <SitoContainer ignoreDefault className="row">
                      <SitoContainer ignoreDefault className="col-lg-6">
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor="password">
                            {languageState.texts.SignIn.inputs[InputsEnum.Password].label}
                          </label>
                          <input
                            type="password"
                            id="password"
                            {...register("password")}
                            onInput={validate}
                            onInvalid={invalidate}
                          />
                        </SitoContainer>
                      </SitoContainer>
                      <SitoContainer ignoreDefault className="col-lg-6">
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor="rpassword">
                            {languageState.texts.SignIn.inputs[InputsEnum.RPassword].label}
                          </label>
                          <input
                            type="password"
                            id="rpassword"
                            {...register("rpassword")}
                            onInput={validate}
                            onInvalid={invalidate}
                          />
                        </SitoContainer>
                      </SitoContainer>

                      <SitoContainer ignoreDefault className="col-md-6">
                        <button type="submit" className="butn">
                          {languageState.texts.Dashboard.Submit.Save}
                        </button>
                      </SitoContainer>
                    </SitoContainer>
                  </form>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Me;
