/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useEffect } from "react";

// sito components
import SitoContainer from "sito-container";

// utils
import { scrollTo } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

const Main = () => {
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();

  useEffect(() => {
    setRouteState({
      type: "set",
      to: 0,
      page: languageState.texts.Dashboard.Sidebar.Links[2].label,
    });
    scrollTo();
  }, []);

  return (
    <main className="dashboard-content">
      <SitoContainer ignoreDefault className="dashboard-container-fluid dashboard-p-0">
        <h1 className="dashboard-h3 dashboard-mb-3">
          <strong>Analytics</strong> Dashboard
        </h1>

        <SitoContainer ignoreDefault className="dashboard-row">
          <SitoContainer
            ignoreDefault
            className="dashboard-col-xl-6 dashboard-col-xxl-5 dashboard-d-flex"
          >
            <SitoContainer ignoreDefault className="dashboard-w-100">
              <SitoContainer ignoreDefault className="dashboard-row">
                <SitoContainer ignoreDefault className="dashboard-col-sm-6">
                  <SitoContainer ignoreDefault className="dashboard-card">
                    <SitoContainer ignoreDefault className="dashboard-card-body">
                      <SitoContainer ignoreDefault className="dashboard-row">
                        <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                          <h5 className="dashboard-card-title">Sales</h5>
                        </SitoContainer>

                        <SitoContainer ignoreDefault className="dashboard-col-auto">
                          <SitoContainer
                            ignoreDefault
                            className="dashboard-stat dashboard-text-primary"
                          >
                            <i className="dashboard-align-middle" data-feather="truck"></i>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                      <h1 className="dashboard-mt-1 dashboard-mb-3">2.382</h1>
                      <SitoContainer ignoreDefault className="dashboard-mb-0">
                        <span className="dashboard-text-danger">
                          <i className="dashboard-mdi dashboard-mdi-arrow-bottom-right"></i> -3.65%
                        </span>
                        <span className="dashboard-text-muted">Since last week</span>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                  <SitoContainer ignoreDefault className="dashboard-card">
                    <SitoContainer ignoreDefault className="dashboard-card-body">
                      <SitoContainer ignoreDefault className="dashboard-row">
                        <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                          <h5 className="dashboard-card-title">Visitors</h5>
                        </SitoContainer>

                        <SitoContainer ignoreDefault className="dashboard-col-auto">
                          <SitoContainer
                            ignoreDefault
                            className="dashboard-stat dashboard-text-primary"
                          >
                            <i className="dashboard-align-middle" data-feather="users"></i>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                      <h1 className="dashboard-mt-1 dashboard-mb-3">14.212</h1>
                      <SitoContainer ignoreDefault className="dashboard-mb-0">
                        <span className="dashboard-text-success">
                          <i className="dashboard-mdi dashboard-mdi-arrow-bottom-right"></i> 5.25%
                        </span>
                        <span className="dashboard-text-muted">Since last week</span>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
                <SitoContainer ignoreDefault className="dashboard-col-sm-6">
                  <SitoContainer ignoreDefault className="dashboard-card">
                    <SitoContainer ignoreDefault className="dashboard-card-body">
                      <SitoContainer ignoreDefault className="dashboard-row">
                        <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                          <h5 className="dashboard-card-title">Earnings</h5>
                        </SitoContainer>

                        <SitoContainer ignoreDefault className="dashboard-col-auto">
                          <SitoContainer
                            ignoreDefault
                            className="dashboard-stat dashboard-text-primary"
                          >
                            <i className="dashboard-align-middle" data-feather="dollar-sign"></i>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                      <h1 className="dashboard-mt-1 dashboard-mb-3">$21.300</h1>
                      <SitoContainer ignoreDefault className="dashboard-mb-0">
                        <span className="dashboard-text-success">
                          <i className="dashboard-mdi dashboard-mdi-arrow-bottom-right"></i> 6.65%
                        </span>
                        <span className="dashboard-text-muted">Since last week</span>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                  <SitoContainer ignoreDefault className="dashboard-card">
                    <SitoContainer ignoreDefault className="dashboard-card-body">
                      <SitoContainer ignoreDefault className="dashboard-row">
                        <SitoContainer ignoreDefault className="dashboard-col dashboard-mt-0">
                          <h5 className="dashboard-card-title">Orders</h5>
                        </SitoContainer>

                        <SitoContainer ignoreDefault className="dashboard-col-auto">
                          <SitoContainer
                            ignoreDefault
                            className="dashboard-stat dashboard-text-primary"
                          >
                            <i className="dashboard-align-middle" data-feather="shopping-cart"></i>
                          </SitoContainer>
                        </SitoContainer>
                      </SitoContainer>
                      <h1 className="dashboard-mt-1 dashboard-mb-3">64</h1>
                      <SitoContainer ignoreDefault className="dashboard-mb-0">
                        <span className="dashboard-text-danger">
                          <i className="dashboard-mdi dashboard-mdi-arrow-bottom-right"></i> -2.25%
                        </span>
                        <span className="dashboard-text-muted">Since last week</span>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>

          <SitoContainer ignoreDefault className="dashboard-col-xl-6 dashboard-col-xxl-7">
            <SitoContainer
              ignoreDefault
              className="dashboard-card dashboard-flex-fill dashboard-w-100"
            >
              <SitoContainer ignoreDefault className="dashboard-card-header">
                <h5 className="dashboard-card-title dashboard-mb-0">Recent Movement</h5>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-card-body dashboard-py-3">
                <SitoContainer ignoreDefault className="dashboard-chart dashboard-chart-sm">
                  <canvas id="chartjs-dashboard-line"></canvas>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>

        <SitoContainer ignoreDefault className="dashboard-row">
          <SitoContainer
            ignoreDefault
            className="dashboard-col-12 dashboard-col-md-6 dashboard-col-xxl-3 dashboard-d-flex dashboard-order-2 dashboard-order-xxl-3"
          >
            <SitoContainer
              ignoreDefault
              className="dashboard-card dashboard-flex-fill dashboard-w-100"
            >
              <SitoContainer ignoreDefault className="dashboard-card-header">
                <h5 className="dashboard-card-title dashboard-mb-0">Browser Usage</h5>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-card-body dashboard-d-flex">
                <SitoContainer
                  ignoreDefault
                  className="dashboard-align-self-center dashboard-w-100"
                >
                  <SitoContainer ignoreDefault className="dashboard-py-3">
                    <SitoContainer ignoreDefault className="dashboard-chart dashboard-chart-xs">
                      <canvas id="chartjs-dashboard-pie"></canvas>
                    </SitoContainer>
                  </SitoContainer>

                  <table className="dashboard-table dashboard-mb-0">
                    <tbody>
                      <tr>
                        <td>Chrome</td>
                        <td className="dashboard-text-end">4306</td>
                      </tr>
                      <tr>
                        <td>Firefox</td>
                        <td className="dashboard-text-end">3801</td>
                      </tr>
                      <tr>
                        <td>IE</td>
                        <td className="dashboard-text-end">1689</td>
                      </tr>
                    </tbody>
                  </table>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer
            ignoreDefault
            className="dashboard-col-12 dashboard-col-md-12 dashboard-col-xxl-6 dashboard-d-flex dashboard-order-3 dashboard-order-xxl-2"
          >
            <SitoContainer
              ignoreDefault
              className="dashboard-card dashboard-flex-fill dashboard-w-100"
            >
              <SitoContainer ignoreDefault className="dashboard-card-header">
                <h5 className="dashboard-card-title dashboard-mb-0">Real-Time</h5>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-card-body dashboard-px-4">
                <SitoContainer
                  ignoreDefault
                  id="world_map"
                  style={{ height: "350px" }}
                ></SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer
            ignoreDefault
            className="dashboard-col-12 dashboard-col-md-6 dashboard-col-xxl-3 dashboard-d-flex dashboard-order-1 dashboard-order-xxl-1"
          >
            <SitoContainer ignoreDefault className="dashboard-card dashboard-flex-fill">
              <SitoContainer className="dashboard-card-header">
                <h5 className="dashboard-card-title dashboard-mb-0">Calendar</h5>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-card-body dashboard-d-flex">
                <SitoContainer
                  ignoreDefault
                  className="dashboard-align-self-center dashboard-w-100"
                >
                  <SitoContainer ignoreDefault className="dashboard-chart">
                    <SitoContainer ignoreDefault id="datetimepicker-dashboard"></SitoContainer>
                  </SitoContainer>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>

        <SitoContainer ignoreDefault className="dashboard-row">
          <SitoContainer
            ignoreDefault
            className="dashboard-col-12 dashboard-col-lg-8 dashboard-col-xxl-9 dashboard-d-flex"
          >
            <SitoContainer ignoreDefault className="dashboard-card dashboard-flex-fill">
              <SitoContainer ignoreDefault className="dashboard-card-header">
                <h5 className="dashboard-card-title dashboard-mb-0">Latest Projects</h5>
              </SitoContainer>
              <table className="dashboard-table dashboard-table-hover dashboard-my-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="dashboard-d-none dashboard-d-xl-table-cell">Start Date</th>
                    <th className="dashboard-d-none dashboard-d-xl-table-cell">End Date</th>
                    <th>Status</th>
                    <th className="dashboard-d-none dashboard-d-md-table-cell">Assignee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Project Apollo</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-success">Done</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">Vanessa Tucker</td>
                  </tr>
                  <tr>
                    <td>Project Fireball</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-danger">Cancelled</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">William Harris</td>
                  </tr>
                  <tr>
                    <td>Project Hades</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-success">Done</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">Sharon Lessman</td>
                  </tr>
                  <tr>
                    <td>Project Nitro</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-warning">In progress</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">Vanessa Tucker</td>
                  </tr>
                  <tr>
                    <td>Project Phoenix</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-success">Done</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">William Harris</td>
                  </tr>
                  <tr>
                    <td>Project X</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-success">Done</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">Sharon Lessman</td>
                  </tr>
                  <tr>
                    <td>Project Romeo</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-success">Done</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">Christina Mason</td>
                  </tr>
                  <tr>
                    <td>Project Wombat</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">01/01/2021</td>
                    <td className="dashboard-d-none dashboard-d-xl-table-cell">31/06/2021</td>
                    <td>
                      <span className="dashboard-badge dashboard-bg-warning">In progress</span>
                    </td>
                    <td className="dashboard-d-none dashboard-d-md-table-cell">William Harris</td>
                  </tr>
                </tbody>
              </table>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer
            ignoreDefault
            className="dashboard-col-12 dashboard-col-lg-4 dashboard-col-xxl-3 dashboard-d-flex"
          >
            <SitoContainer
              ignoreDefault
              className="dashboard-card dashboard-flex-fill dashboard-w-100"
            >
              <SitoContainer ignoreDefault className="dashboard-card-header">
                <h5 className="dashboard-card-title mb-0">Monthly Sales</h5>
              </SitoContainer>
              <SitoContainer ignoreDefault className="dashboard-card-body dashboard-d-flex w-100">
                <SitoContainer
                  ignoreDefault
                  className="dashboard-align-self-center dashboard-chart dashboard-chart-lg"
                >
                  <canvas id="chartjs-dashboard-bar"></canvas>
                </SitoContainer>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </main>
  );
};

export default Main;
