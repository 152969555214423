/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { useState } from "react";
import { useForm } from "react-hook-form";

// sito components
import SitoContainer from "sito-container";
// import SitoImage from "sito-image";

// own components
import Loader from "components/Loader/Loader";

// services
import { createUser, createAgent } from "services/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// functions
import { passwordValidation, isAdmin } from "utils/functions";
import { UserTypesEnum } from "utils/Enum";

const Insert = () => {
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { register, handleSubmit, getValues } = useForm();
  const [role, setRole] = useState(1);
  const [userType, setUserType] = useState(0);

  const changeUserType = (e) => setUserType(Number(e.target.value));

  const [loading, setLoading] = useState(false);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const localCreateUser = async (d) => {
    const roles = ["INMOBILIARIA", "PROPIETARIO"];
    setLoading(true);
    const { user, password, rpassword } = d;
    if (password === rpassword) {
      try {
        const {
          name,
          lastname,
          email,
          fertilizer,
          college,
          phone,
          dni,
          matricula,
          cuit,
          businessName,
          web,
          facebook,
          twitter,
          instagram,
        } = d;
        let response;
        if (userType === UserTypesEnum.Normal)
          response = await createUser({
            nombre: name,
            apellidos: lastname,
            email,
            abono_id: Number(fertilizer),
            colegio_id: Number(college),
            telefono: phone,
            rol: roles[role],
            password_hash: password,
            password_hash_repeat: rpassword,
            user,
            dni: role === 1 ? dni : "",
            matricula: role === 1 ? "" : matricula,
            cuit: role === 1 ? "" : cuit,
            razon_social: role === 1 ? "" : businessName,
            web: role === 1 ? "" : web,
            facebook: role === 1 ? "" : facebook,
            twitter: role === 1 ? "" : twitter,
            instagram: role === 1 ? "" : instagram,
          });
        else
          response = await createAgent({
            nombre: name,
            apellidos: lastname,
            email,
            telefono: phone,
            password_hash: password,
            password_hash_repeat: rpassword,
            user,
          });
        if (response.status === 200)
          await setNotificationState({
            type: "set",
            message: languageState.texts.Messages.UserCreatedSuccessful,
            ntype: "success",
          });
      } catch (err) {
        if (err.message.indexOf("422") > -1) {
          const error = err.response.data.data;
          const newInvalids = invalids;
          console.log(error);
          error.forEach((item, i) => {
            newInvalids[item.field] = true;
            if (i === 0) document.getElementById(item.field).focus();
          });
          setInvalids(newInvalids);
          let message = languageState.texts.Errors.SomeWrong;
          if (error.length === 1) message = languageState.texts.Errors.ServerErrors[error[0].field];
          setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
      }
    } else
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.DifferentPassword,
      });

    setLoading(false);
  };

  const changeRole = (e) => {
    const { value } = e.target;
    if (Number(value) === 0) return setRole(1);
    return setRole(0);
  };

  const validate = () => {
    setOk(true);
  };

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    const { user, email, password, rpassword } = getValues();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "tuition":
        case "email":
          message = languageState.texts.Errors.InvalidEmail;
          if (email.length === 0) message = languageState.texts.Errors.EmailRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "user":
          message = languageState.texts.Errors.NameRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            user
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.TermsNdConditionRequired,
          });
      }
    }
  };

  return (
    <SitoContainer sx={{ position: "relative", minHeight: "300px" }}>
      <Loader visible={loading} minimal />
      {!loading && (
        <form id="register-form" method="post" action="#!" onSubmit={handleSubmit(localCreateUser)}>
          <SitoContainer alignItems="center" justifyContent="space-between">
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.User.Insert.Title}
            </h3>
            <SitoContainer>
              <select
                className="form-control"
                id="userType"
                value={userType}
                onChange={changeUserType}
              >
                {languageState.texts.Dashboard.User.Type.Types.map((jtem, i) => (
                  <option key={jtem.label} value={i}>
                    {jtem.label}
                  </option>
                ))}
              </select>
            </SitoContainer>
          </SitoContainer>
          <SitoContainer ignoreDefault className="row">
            {languageState.texts.SignIn.inputs
              .filter((item) => {
                if ((item.admin || !item.admin) && isAdmin() && userType === UserTypesEnum.Normal)
                  return item;
                if (!isAdmin() && !item.admin && userType === UserTypesEnum.Normal) return item;
                if (userType === UserTypesEnum.Agent && item.agent) return item;
                return null;
              })
              .map((item) => (
                <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                  {!item.hidden ? (
                    <>
                      {item.type !== "select" &&
                        ((item.role && item.role === role + 1) || !item.role) && (
                          <SitoContainer ignoreDefault className="form-group">
                            <label htmlFor={item.id}>
                              {`${item.label} ${item.required ? "*" : ""}`}
                            </label>
                            <input
                              placeholder={item.placeholder}
                              type={item.type}
                              name={item.id}
                              id={item.id}
                              maxLength={item.maxLength}
                              required={item.required}
                              onInput={validate}
                              onInvalid={invalidate}
                              {...register(item.id)}
                              className={invalids[item.id] ? "error" : ""}
                            />
                          </SitoContainer>
                        )}
                      {item.type === "select" && isAdmin() && (
                        <SitoContainer ignoreDefault className="form-group">
                          <label htmlFor={item.id}>
                            {`${item.label} ${item.required ? "*" : ""}`}
                          </label>
                          <select
                            className="form-control"
                            id={item.id}
                            {...register(item.id)}
                            onChange={changeRole}
                          >
                            {item.options.map((jtem, i) => (
                              <option key={jtem.label} value={i}>
                                {jtem.label}
                              </option>
                            ))}
                          </select>
                        </SitoContainer>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </SitoContainer>
              ))}
          </SitoContainer>

          <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
            <SitoContainer ignoreDefault className="col-md-6">
              <button type="submit" className="butn">
                {languageState.texts.Dashboard.Submit.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
    </SitoContainer>
  );
};

export default Insert;
