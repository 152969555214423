/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

import useScrollTrigger from "@mui/material/useScrollTrigger";

import { Link } from "react-router-dom";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// utils
import { userLogged, isAdmin } from "utils/functions";

// image
import logo from "img/logos/logo.png";
import logoWhite from "img/logos/logo-white.png";

function Navbar() {
  const { languageState } = useLanguage();
  const { routeState, setRouteState } = useRoute();
  const [currentLink, setCurrentLink] = useState(0);

  const trigger = useScrollTrigger();

  useEffect(() => {
    if (routeState.route !== undefined) setCurrentLink(routeState.route);
  }, [routeState]);

  useEffect(() => {}, [currentLink]);

  return (
    <header
      className={`${currentLink === 0 ? "position-absolute" : ""} width-100 ${
        !trigger ? (currentLink === 0 ? "transparent-header" : "fixedHeader") : "scrollHeader"
      }`}
    >
      <SitoContainer ignoreDefault className="navbar-default">
        {/* <!-- start top search --> */}
        <SitoContainer ignoreDefault className="top-search bg-black-opacity-light">
          <SitoContainer ignoreDefault className="container">
            <form className="search-form" action="search.html" method="GET" acceptCharset="utf-8">
              <SitoContainer ignoreDefault className="input-group">
                <span className="input-group-addon cursor-pointer">
                  <button
                    className="search-form_submit fas fa-search font-size18 text-white"
                    type="submit"
                  />
                </span>
                <input
                  type="text"
                  className="search-form_input form-control"
                  name="s"
                  autoComplete="off"
                  placeholder="Type & hit enter..."
                />
                <span className="input-group-addon close-search">
                  <i className="fas fa-times font-size18 margin-5px-top" />
                </span>
              </SitoContainer>
            </form>
          </SitoContainer>
        </SitoContainer>
        {/* <!-- end top search --> */}

        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row align-items-center">
            <SitoContainer ignoreDefault className="col-12 col-lg-12">
              <SitoContainer ignoreDefault className="menu_area">
                <nav className="navbar navbar-expand-lg navbar-light no-padding">
                  <SitoContainer ignoreDefault className="navbar-header navbar-header-custom">
                    {/* <!-- start logo --> */}
                    <Link
                      to="/"
                      onClick={() => setRouteState({ type: "set", to: 0 })}
                      className="navbar-brand inner-logo"
                    >
                      <SitoImage
                        id="logo"
                        src={currentLink === 0 ? (!trigger ? logoWhite : logo) : logo}
                        alt="logo"
                        sx={{ width: "auto" }}
                      />
                    </Link>
                    {/* <!-- end logo --> */}
                  </SitoContainer>

                  <SitoContainer ignoreDefault className="navbar-toggler" />

                  {/* <!-- start menu area --> */}

                  <ul className="navbar-nav ml-auto" id="nav">
                    {languageState.texts.Links.filter((item) => {
                      if (item.logged === 0) return item;
                      if (item.logged > 0 && userLogged())
                        if ((item.admin && isAdmin()) || !item.admin) return item;
                      if (item.logged < 0 && !userLogged()) return item;
                      return null;
                    }).map((item, i) => (
                      <li key={item.label} className={item.index === currentLink ? "active" : ""}>
                        <Link to={item.to} onClick={() => setRouteState({ type: "set", to: i })}>
                          {item.label}
                        </Link>
                      </li>
                    ))}
                    {/* <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="about-us.html">About Us</a>
                </li>
                <li>
                  <a href="#">Pages</a>
                  <ul>
                    <li>
                      <a href="pricing.html">Pricing</a>
                    </li>
                    <li>
                      <a href="#">Agent</a>
                      <ul>
                        <li>
                          <a href="agent-list.html">Agent List</a>
                        </li>
                        <li>
                          <a href="agent-profile.html">Agent Profile</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">List View</a>
                      <ul>
                        <li>
                          <a href="list-view.html">List View</a>
                        </li>
                        <li>
                          <a href="listing-details-1.html">Listing Detail 1</a>
                        </li>
                        <li>
                          <a href="listing-details-2.html">Listing Detail 2</a>
                        </li>
                        <li>
                          <a href="listing-details-3.html">Listing Detail 3</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="grid-view.html">Grid View</a>
                    </li>
                    <li>
                      <a href="map-view.html">Map View</a>
                    </li>
                    <li>
                      <a href="compare-properties.html">Compare Properties</a>
                    </li>
                    <li>
                      <a href="#">Others</a>
                      <ul>
                        <li>
                          <a href="register.html">Register</a>
                        </li>
                        <li>
                          <a href="login.html">Login</a>
                        </li>
                        <li>
                          <a href="forgot-password.html">Forgot Password</a>
                        </li>
                        <li>
                          <a href="success.html">Success</a>
                        </li>
                        <li>
                          <a href="404-page.html">Error 404</a>
                        </li>
                        <li>
                          <a href="coming-soon.html">Comingsoon</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Account</a>
                  <ul>
                    <li>
                      <a href="my-listing.html">My List</a>
                    </li>
                    <li>
                      <a href="bookmarked-list.html">Bookmarked List</a>
                    </li>
                    <li>
                      <a href="new-list.html">Submit New List</a>
                    </li>
                    <li>
                      <a href="myprofile.html">My Profile</a>
                    </li>
                    <li>
                      <a href="change-password.html">Change Password</a>
                    </li>
                    <li>
                      <a href="notification.html">Notifications</a>
                    </li>
                    <li>
                      <a href="membership.html">Membership</a>
                    </li>
                    <li>
                      <a href="payments.html">Payments</a>
                    </li>
                    <li>
                      <a href="account.html">Account</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Blog</a>
                  <ul>
                    <li>
                      <a href="blog-grid.html">Blog Grid</a>
                    </li>
                    <li>
                      <a href="blog-details.html">Blog Details</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="contact-us.html">Contact Us</a>
                </li> */}
                  </ul>
                  {/* <!-- end menu area --> */}

                  {/* <!-- start attribute navigation --> */}
                  <SitoContainer
                    ignoreDefault
                    className="attr-nav sm-no-margin sm-margin-65px-right"
                  >
                    <ul>
                      <li className="search">
                        <a href="#">
                          <i className="fas fa-search" />
                        </a>
                      </li>
                    </ul>
                  </SitoContainer>
                  {/* <!-- end attribute navigation --> */}
                </nav>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </header>
  );
}

export default Navbar;
