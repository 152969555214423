/* eslint-disable react/function-component-definition */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// utils
import { deleteCookie } from "utils/functions";

import config from "config";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem(config.userCookie);
    localStorage.removeItem(config.passwordCookie);
    sessionStorage.removeItem(config.userCookie);
    deleteCookie(config.jwtKey);
    navigate("/");
  }, []);

  return <div />;
};

export default Logout;
