/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { provinceList } from "services/get";
import { modifyProvince } from "services/post";

// functions
import { getIndexOf } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

const Modify = (props) => {
  const { navigateToInsert } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { register, handleSubmit, setValue } = useForm();

  const [localList, setLocalList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const [provinceToModify, setProvinceToModify] = useState(0);

  const changeProvinceToModify = (e) => setProvinceToModify(Number(e.target.value));

  const fetch = async () => {
    setLoading(true);
    const response = await provinceList();
    if (response.status === 200) {
      const { data } = await response.data;
      if (data.length) {
        setAllData(data);
        let element = getIndexOf(
          "id",
          provinceToModify === 0 ? provinceToModify + 1 : provinceToModify,
          data
        );
        if (element === -1) element = 0;
        // reading default values
        // name - nombre
        if (data[element].nombre) setValue("name", data[element].nombre);
        setLocalList(data);
      }
    } else {
      setLocalList(-1);
      setNotificationState({
        type: "set",
        message: languageState.texts.Errors.NotConnected,
        ntype: "error",
      });
    }
    setLoading(false);
  };

  const retry = () => {
    fetch();
  };

  const localModifyProvince = async (d) => {
    setLoading(true);
    try {
      const { name } = d;
      const data = { nombre: name };
      const response = await modifyProvince(provinceToModify, data);
      if (response.status === 200) {
        setNotificationState({
          type: "set",
          message: languageState.texts.Messages.ProvinceModifiedSuccessful,
          ntype: "success",
        });
        retry();
      }
    } catch (err) {
      if (err.message.indexOf("422") > -1) {
        const error = err.response.data.data;
        const newInvalids = invalids;
        error.forEach((item, i) => {
          newInvalids[item.field] = true;
          if (i === 0) document.getElementById(item.field).focus();
        });
        setInvalids(newInvalids);
        let message = languageState.texts.Errors.SomeWrong;
        if (error.length === 1) message = languageState.texts.Errors.ServerErrors[error[0].field];
        setNotificationState({
          type: "set",
          ntype: "error",
          message,
        });
      }
    }
    setLoading(false);
  };

  const validate = () => {
    setOk(true);
  };

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.NameRequired,
          });
      }
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (allData.length) {
      const element = getIndexOf("id", provinceToModify, allData);
      // name - nombre
      if (allData[element].nombre) setValue("name", allData[element].nombre);
    }
  }, [provinceToModify]);

  return (
    <SitoContainer
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {!loading && localList.length > 0 && (
        <form onSubmit={handleSubmit(localModifyProvince)} className={css({ width: "100%" })}>
          <SitoContainer ignoreDefault className="form-group" sx={{ width: "100%" }}>
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.Province.Modify.Title}
            </h3>
            <label htmlFor="users">{languageState.texts.Dashboard.Province.Modify.Label}</label>
            <select
              className="form-control"
              id="users"
              value={provinceToModify}
              onChange={changeProvinceToModify}
            >
              {localList.map((jtem) => (
                <option key={jtem.id} value={jtem.id}>
                  {jtem.nombre}
                </option>
              ))}
            </select>
            {languageState.texts.SignIn.inputs
              .filter((item) => {
                if (item.models && item.models.indexOf("province") > -1) return item;
                return null;
              })
              .map((item, i) => (
                <SitoContainer ignoreDefault key={item.id} sx={{ marginTop: i === 0 ? "20px" : 0 }}>
                  {item.modify && (
                    <SitoContainer ignoreDefault className="form-group">
                      <label htmlFor={item.id}>{item.label}</label>
                      <input
                        placeholder={item.placeholder}
                        type={item.type}
                        name={item.id}
                        id={item.id}
                        maxLength={item.maxLength}
                        onInput={validate}
                        onInvalid={invalidate}
                        {...register(item.id)}
                        className={invalids[item.id] ? "error" : ""}
                      />
                    </SitoContainer>
                  )}
                </SitoContainer>
              ))}
            <SitoContainer ignoreDefault sx={{ marginTop: "20px" }}>
              <button type="submit" className="butn">
                {languageState.texts.Dashboard.Submit.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
      {!loading && !localList.length && localList !== -1 && <Empty onAction={navigateToInsert} />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

Modify.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
};

export default Modify;
