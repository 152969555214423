/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/function-component-definition */

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// contexts
import { useLanguage } from "context/LanguageProvider";

// image
import logoFooter from "img/logos/logo-footer-small.png";

const Footer = () => {
  const { languageState } = useLanguage();

  return (
    <footer className="footer-style3 bg-white border-top">
      <SitoContainer ignoreDefault className="container">
        <SitoContainer ignoreDefault className="footer-5">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer
              ignoreDefault
              className="col-lg-3 col-sm-7 col-xs-6 sm-margin-30px-bottom"
            >
              <span className="footer-logo margin-25px-bottom display-inline-block">
                <SitoImage src={logoFooter} alt="logo" sx={{ width: "auto", height: "auto" }} />
              </span>
              <ul className="no-margin-bottom">
                <li>
                  <a href="#" className="font-size14">
                    <i className="ti-location-pin margin-10px-right text-theme-color" />
                    Regina ST, London, SK 8GH.
                  </a>
                </li>
                <li>
                  <a href="#" className="font-size14">
                    <i className="ti-email margin-10px-right text-theme-color" />
                    email@youradress.com
                  </a>
                </li>
                <li>
                  <a href="#" className="font-size14">
                    <i className="ti-mobile margin-10px-right text-theme-color" />
                    (+44) 123 456 789
                  </a>
                </li>
              </ul>
            </SitoContainer>

            <SitoContainer
              ignoreDefault
              className="col-lg-3 col-sm-5 col-xs-6 sm-margin-30px-bottom"
            >
              <SitoContainer ignoreDefault className="padding-40px-left sm-no-padding-left">
                <h4>Popular Search</h4>
                <ul className="no-margin-bottom">
                  <li>
                    <a href="#" className="font-size14">
                      Property for Rent
                    </a>
                  </li>
                  <li>
                    <a href="#" className="font-size14">
                      Property for Sale
                    </a>
                  </li>
                  <li>
                    <a href="#" className="font-size14">
                      Resale Properties
                    </a>
                  </li>
                  <li>
                    <a href="#" className="font-size14">
                      Recent Properties
                    </a>
                  </li>
                </ul>
              </SitoContainer>
            </SitoContainer>

            <SitoContainer
              ignoreDefault
              className="col-lg-3 col-sm-7 col-xs-6 mobile-margin-30px-bottom"
            >
              <SitoContainer ignoreDefault className="padding-40px-left sm-no-padding-left">
                <h4>Quick Links</h4>
                <ul className="no-margin-bottom no-padding">
                  <li>
                    <a href="#" className="font-size14">
                      About us
                    </a>
                  </li>
                  <li>
                    <a href="#" className="font-size14">
                      Contact us
                    </a>
                  </li>
                  <li>
                    <a href="#" className="font-size14">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="#" className="font-size14">
                      FAQ
                    </a>
                  </li>
                </ul>
              </SitoContainer>
            </SitoContainer>

            <SitoContainer ignoreDefault className="col-lg-3 col-sm-5 col-xs-6">
              <h4>Subscribe</h4>
              <SitoContainer ignoreDefault className="email-box margin-20px-bottom">
                <SitoContainer ignoreDefault className="input">
                  <form method="post" action="#!">
                    <input type="email" placeholder="Email" />
                    <button type="submit" className="butn">
                      Join
                    </button>
                  </form>
                </SitoContainer>
              </SitoContainer>

              <SitoContainer ignoreDefault className="footer-icon">
                <p>{languageState.texts.Footer.SocialMedia.Title}</p>
                <ul className="no-margin-bottom">
                  {languageState.texts.Footer.SocialMedia.Links.map((item) => (
                    <li key={item.name}>
                      <a target="_blank" rel="noreferrer" href={item.link}>
                        <i className={`fab fa-${item.name}`} />
                      </a>
                    </li>
                  ))}
                </ul>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
      <SitoContainer ignoreDefault className="footer-style3-bottom">
        <SitoContainer ignoreDefault className="container">
          <p className="font-size14">&copy; 2020 Landmark is Powered by Chitrakoot Web</p>
        </SitoContainer>
      </SitoContainer>
    </footer>
  );
};

export default Footer;
