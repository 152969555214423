/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from "prop-types";

// layouts
import Section from "layouts/Section/Section";

// image
import img from "img/reals/header/page-title.webp";

const Hero = (props) => {
  const { children } = props;

  return (
    <Section
      className="page-title-section bg-img cover-background"
      extraProps={{ "data-overlay-dark": "0" }}
      background={img}
    >
      {children}
    </Section>
  );
};

Hero.defaultProps = {
  children: <></>,
};

Hero.propTypes = {
  children: PropTypes.node,
};

export default Hero;
