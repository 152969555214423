/* eslint-disable react/function-component-definition */

import { Link } from "react-router-dom";

import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";
import SitoImage from "sito-image";

// layouts
import Section from "layouts/Section/Section";

// image
import blog01 from "img/reals/categories/01.webp";
import blog02 from "img/reals/categories/02.webp";
import blog03 from "img/reals/categories/03.webp";

// contexts
import { useLanguage } from "context/LanguageProvider";

const UserCategories = () => {
  const { languageState } = useLanguage();

  const images = [blog01, blog02, blog03];

  const linkCss = css({
    transition: "none !important",
    h5: {
      transition: "all 500ms ease !important",
    },
    p: {
      transition: "all 500ms ease !important",
    },
    "&:hover": {
      h5: {
        color: "#ff9d33",
      },
      p: {
        color: "#ff9d33",
      },
    },
  });

  return (
    <Section>
      <SitoContainer ignoreDefault className="container">
        <SitoContainer
          ignoreDefault
          className="margin-50px-bottom xs-margin-40px-bottom text-center"
        >
          <h4 className="font-size38 xs-font-size28 line-height-45 font-weight-500 no-margin-bottom">
            {languageState.texts.Home.UserCategories.Title}
          </h4>
        </SitoContainer>

        <SitoContainer ignoreDefault className="row">
          {languageState.texts.Home.UserCategories.Categories.map((item, i) => (
            <SitoContainer key={item.name} ignoreDefault className="col-lg-4 sm-margin-40px-bottom">
              <Link to="/login/register" className={linkCss}>
                <SitoImage
                  src={images[i]}
                  alt=""
                  sx={{ borderRadius: "3px", width: "100%", height: "100%" }}
                />
                <SitoContainer ignoreDefault className="blog-text">
                  <h5 className="font-size20 xs-font-size18 margin-5px-top margin-5px-bottom font-weight-500">
                    {item.name}
                  </h5>
                  <p>{item.description}</p>
                </SitoContainer>
              </Link>
            </SitoContainer>
          ))}
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

export default UserCategories;
