/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

// @mui-icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// sito components
import SitoContainer from "sito-container";

import "./style.css";

const Carousel = (props) => {
  const { content, height, indicators, id, hasBackground } = props;

  return (
    <SitoContainer
      ignoreDefault
      id={`${id}Controls`}
      className="carousel slide slider-fade"
      extraProps={{ "data-ride": "carousel" }}
      sx={{
        height,
      }}
    >
      {indicators && (
        <ol className="carousel-indicators">
          {content.map((item, i) => (
            <li
              key={i}
              data-target={`#${id}Indicators`}
              data-slide-to="0"
              className={i === 0 ? "active" : ""}
            ></li>
          ))}
        </ol>
      )}

      <SitoContainer ignoreDefault className="carousel-inner" sx={{ height: "100%" }}>
        {content.map((item, i) => (
          <SitoContainer
            key={i}
            ignoreDefault
            sx={{ height: "100%" }}
            className={`carousel-item ${i === 0 ? "active" : ""}`}
          >
            {item}
          </SitoContainer>
        ))}
      </SitoContainer>
      <a className="carousel-control-prev" href={`#${id}Controls`} role="button" data-slide="prev">
        {!hasBackground && <ChevronLeftIcon sx={{ color: "#222" }} />}
        {hasBackground && <span className="carousel-control-prev-icon" aria-hidden="true"></span>}
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href={`#${id}Controls`} role="button" data-slide="next">
        {!hasBackground && <ChevronRightIcon sx={{ color: "#222" }} />}
        {hasBackground && <span className="carousel-control-next-icon" aria-hidden="true"></span>}
        <span className="sr-only">Next</span>
      </a>
    </SitoContainer>
  );
};

Carousel.defaultProps = {
  height: "100vh",
  id: "",
  indicators: false,
  hasBackground: true,
};

Carousel.propTypes = {
  content: PropTypes.arrayOf(PropTypes.node).isRequired,
  height: PropTypes.string,
  id: PropTypes.string,
  hasBackground: PropTypes.bool,
  indicators: PropTypes.bool,
};

export default Carousel;
