/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// services
import { signUp } from "services/post";

// config
import config from "config";

// functions
import {
  passwordValidation,
  userLogged,
  scrollTo,
  createValidatingCookie,
  isAdmin,
} from "utils/functions";

const Login = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, getValues } = useForm();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();
  const [role, setRole] = useState(1);

  const [ok, setOk] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [invalids, setInvalids] = useState({});

  const [term, setTerm] = useState(false);

  const [loading, setLoading] = useState(false);

  const toggleTerm = () => setTerm(!term);

  const localSignUp = async (d) => {
    const roles = ["INMOBILIARIA", "PROPIETARIO"];
    setLoading(true);
    const { user, password, rpassword } = d;
    if (password === rpassword) {
      try {
        const {
          name,
          lastname,
          email,
          fertilizer,
          college,
          phone,
          dni,
          matricula,
          cuit,
          businessName,
          web,
          facebook,
          twitter,
          instagram,
        } = d;
        const response = await signUp({
          nombre: name,
          apellidos: lastname,
          email,
          abono_id: Number(fertilizer),
          colegio_id: Number(college),
          telefono: phone,
          rol: roles[role],
          password_hash: password,
          password_hash_repeat: rpassword,
          user,
          dni: role === 1 ? dni : "",
          matricula: role === 1 ? "" : matricula,
          cuit: role === 1 ? "" : cuit,
          razon_social: role === 1 ? "" : businessName,
          web: role === 1 ? "" : web,
          facebook: role === 1 ? "" : facebook,
          twitter: role === 1 ? "" : twitter,
          instagram: role === 1 ? "" : instagram,
        });
        if (response.status === 200) {
          sessionStorage.setItem(config.userCookie, user);
          createValidatingCookie(email);
          sessionStorage.setItem(config.validatingCookie, email);
          setNotificationState({
            type: "set",
            message: languageState.texts.Messages.UserCreatedSuccessful,
            ntype: "success",
          });
          setTimeout(() => {
            navigate("/email-validation");
          }, 100);
        }
      } catch (err) {
        console.log(err);
        if (err.message.indexOf("422") > -1) {
          const error = err.response.data.data;
          const newInvalids = invalids;
          error.forEach((item, i) => {
            newInvalids[item.field] = true;
            if (i === 0) document.getElementById(item.field).focus();
          });
          setInvalids(newInvalids);
          let message = languageState.texts.Errors.SomeWrong;
          if (error.length === 1) message = languageState.texts.Errors.ServerErrors[error[0].field];
          setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
      }
    } else
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.DifferentPassword,
      });

    setLoading(false);
  };

  useEffect(() => {
    if (userLogged()) {
      if (isAdmin()) navigate("/dashboard");
      else navigate("/profile");
    }
    const registerForm = document.getElementById("register-form");
    scrollTo((registerForm.offsetHeight - registerForm.offsetTop) / 2);
    setRouteState({ type: "set", to: 3, page: languageState.texts.Links[3].label });
  }, []);

  const changeRole = (e) => {
    const { value } = e.target;
    if (Number(value) === 0) return setRole(1);
    return setRole(0);
  };

  const validate = () => {
    setOk(true);
  };

  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    const { user, email, password, rpassword } = getValues();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "tuition":
        case "email":
          message = languageState.texts.Errors.InvalidEmail;
          if (email.length === 0) message = languageState.texts.Errors.EmailRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "user":
          message = languageState.texts.Errors.NameRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            user
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.TermsNdConditionRequired,
          });
      }
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[3].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul>
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <li>
                  <Link to="/login/register" disabled>
                    {languageState.texts.Links[3].label}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-7 center-col">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-all xs-padding-25px-all border-radius-3"
              >
                <h3 className="text-center font-size30">{languageState.texts.Links[3].label}</h3>
                <form
                  id="register-form"
                  method="post"
                  action="#!"
                  onSubmit={handleSubmit(localSignUp)}
                >
                  <SitoContainer ignoreDefault className="row">
                    {languageState.texts.SignIn.inputs.map((item) => (
                      <SitoContainer ignoreDefault key={item.id} className="col-md-12">
                        {!item.hidden ? (
                          <>
                            {item.type !== "select" &&
                              ((item.role && item.role === role + 1) || !item.role) && (
                                <SitoContainer ignoreDefault className="form-group">
                                  <label htmlFor={item.id}>
                                    {`${item.label} ${item.required ? "*" : ""}`}
                                  </label>
                                  <input
                                    placeholder={item.placeholder}
                                    type={item.type}
                                    name={item.id}
                                    id={item.id}
                                    maxLength={item.maxLength}
                                    required={item.required}
                                    onInput={validate}
                                    onInvalid={invalidate}
                                    {...register(item.id)}
                                    className={invalids[item.id] ? "error" : ""}
                                  />
                                </SitoContainer>
                              )}
                            {item.type === "select" && (
                              <SitoContainer ignoreDefault className="form-group">
                                <label htmlFor={item.id}>
                                  {`${item.label} ${item.required ? "*" : ""}`}
                                </label>
                                <select
                                  className="form-control"
                                  id={item.id}
                                  {...register(item.id)}
                                  onChange={changeRole}
                                >
                                  {item.options.map((jtem, i) => (
                                    <option key={jtem.label} value={i}>
                                      {jtem.label}
                                    </option>
                                  ))}
                                </select>
                              </SitoContainer>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </SitoContainer>
                    ))}
                    <SitoContainer ignoreDefault className="col-12">
                      <SitoContainer ignoreDefault className="form-group">
                        <label htmlFor="remember" className="no-margin-bottom">
                          <input
                            id="terms"
                            onChange={toggleTerm}
                            type="checkbox"
                            className="min-height-auto"
                            required
                            onInput={validate}
                            onInvalid={invalidate}
                          />
                          {languageState.texts.SignIn.terms.label}
                          <Link to="/terms-condition" style={{ textDecoration: "underline" }}>
                            {languageState.texts.SignIn.terms.link}
                          </Link>
                        </label>
                      </SitoContainer>
                    </SitoContainer>
                  </SitoContainer>

                  <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.SignIn.titleUp}
                      </button>
                    </SitoContainer>
                    <SitoContainer
                      ignoreDefault
                      className="col-md-6 text-right xs-text-left xs-margin-20px-top"
                    >
                      <p>
                        {languageState.texts.SignIn.signIn.message}{" "}
                        <Link to="/login" style={{ textDecoration: "underline" }}>
                          {languageState.texts.SignIn.signIn.link}
                        </Link>
                      </p>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Login;
