/* eslint-disable import/prefer-default-export */
export const InputsEnum = {
  Name: 0,
  LastName: 1,
  Email: 2,
  Phone: 3,
  Rol: 4,
  User: 5,
  Password: 6,
  RPassword: 7,
  Tuition: 8,
  Fertilizer: 9,
  Cuit: 10,
  BusinessName: 11,
  College: 12,
  Web: 13,
  Facebook: 14,
  Twitter: 15,
  Instagram: 16,
  DNI: 17,
};

export const UserTypesEnum = {
  Normal: 0,
  Agent: 1,
};
