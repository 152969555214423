/* eslint-disable react/function-component-definition */
import { Link } from "react-router-dom";

// @mui icons
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";

import PropTypes from "prop-types";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

const Sidebar = (props) => {
  const { currentLink, onAction, state } = props;
  const { languageState } = useLanguage();

  const icons = [
    <PersonIcon className="margin-10px-right" />,
    <PeopleIcon className="margin-10px-right" />,
    <LogoutIcon className="margin-10px-right" />,
  ];

  return (
    <SitoContainer ignoreDefault>
      <ul className="no-margin-bottom list-style13">
        {languageState.texts.Profile.Sidebar.Links.filter((item) => {
          if (item.state && state) return item;
          if (!item.state) return item;
          return null;
        }).map((item, i) => (
          <li key={item.label}>
            <Link
              to={item.link}
              onClick={() => onAction(i)}
              className={currentLink === i ? "text-theme-color" : ""}
            >
              {icons[item.icon]}
              {item.label}
            </Link>
          </li>
        ))}
        {/*
        <li>
          <a href="my-listing.html">
            <i className="ti-menu-alt margin-10px-right"></i> My Listing
          </a>
        </li>
        <li>
          <a href="bookmarked-list.html">
            <i className="ti-bookmark margin-10px-right"></i> Bookmarked List
          </a>
        </li>
        <li>
          <a href="new-list.html">
            <i className="ti-layers margin-10px-right"></i> Submit New List
          </a>
        </li>
        <li>
          <a href="myprofile.html">
            <i className="ti-user margin-10px-right"></i> My Profile
          </a>
        </li>
        <li>
          <a href="change-password.html">
            <i className="ti-lock margin-10px-right"></i> Change Password
          </a>
        </li>
        <li>
          <a href="notification.html">
            <i className="ti-bell margin-10px-right"></i> Notifications
          </a>
        </li>
        <li>
          <a href="membership.html">
            <i className="ti-layers-alt margin-10px-right"></i> Membership
          </a>
        </li>
        <li>
          <a href="payments.html">
            <i className="ti-credit-card margin-10px-right"></i> Payments
          </a>
        </li>
        <li>
          <a href="account.html" className="text-theme-color">
            <i className="ti-settings margin-10px-right"></i> Account
          </a>
        </li>
         */}
      </ul>
    </SitoContainer>
  );
};

Sidebar.defaultProps = {
  state: false,
};

Sidebar.propTypes = {
  currentLink: PropTypes.number.isRequired,
  onAction: PropTypes.func.isRequired,
  state: PropTypes.bool,
};

export default Sidebar;
