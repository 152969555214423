/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

// sito components
import SitoContainer from "sito-container";

// layouts
import Section from "layouts/Section/Section";

const Map = () => {
  return (
    <Section class="no-padding-top">
      <SitoContainer ignoreDefault class="container">
        <SitoContainer ignoreDefault class="row">
          <SitoContainer ignoreDefault class="col-lg-12">
            <iframe
              className="map-height"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=london&t=&z=13&ie=UTF8&iwloc=&output=embed"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </Section>
  );
};

export default Map;
