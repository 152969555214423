/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { getAuth } from "auth/auth";
import config from "config";

// cookies
import { getCookie } from "utils/functions";

export const getNotification = async () => {
  let response;
  try {
    response = await axios.get(`${config.apiUrl}/notification`, {
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};

export const userList = async () => {
  let response;
  try {
    response = await axios.get(`${config.apiUrl}/user`, {
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};

export const provinceList = async () => {
  let response;
  try {
    response = await axios.get(`${config.apiUrl}/provincia`, {
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};

export const getMe = async () => {
  let response;
  try {
    response = await axios.get(`${config.apiUrl}/user/me`, {
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};

export const getAgents = async () => {
  let response;
  try {
    response = await axios.get(`${config.apiUrl}/agente`, {
      headers: { ...getAuth, Authorization: `Bearer ${getCookie(config.jwtKey)}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return { error: String(err) };
  }
};
