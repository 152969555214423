/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui icons
import TuneIcon from "@mui/icons-material/Tune";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PlaceIcon from "@mui/icons-material/Place";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

// contexts
import { useRoute } from "context/RouterProvider";

const SideLink = (props) => {
  const { routeState } = useRoute();

  const icons = [
    <TuneIcon className="dashboard-align-middle" />,
    <EqualizerIcon className="dashboard-align-middle" />,
    <PeopleAltIcon className="dashboard-align-middle" />,
    <PlaceIcon className="dashboard-align-middle" />,
    <AccountCircleIcon className="dashboard-align-middle" />,
    <SettingsIcon className="dashboard-align-middle" />,
    <LogoutIcon className="dashboard-align-middle" />,
  ];

  const { content } = props;
  return (
    <li
      className={`dashboard-sidebar-item ${
        content.index === routeState.route ? "dashboard-sidebar-item-active" : ""
      }`}
    >
      <Link className="dashboard-sidebar-link" to={content.link}>
        {icons[content.index]}
        <span className="dashboard-align-middle">{content.label}</span>
      </Link>
    </li>
  );
};

SideLink.propTypes = {
  content: PropTypes.object.isRequired,
};

export default SideLink;
