/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";

// sito components
import SitoContainer from "sito-container";

// own components
import TabView from "components/MUI/TabView/TabView";

// utils
import { scrollTo } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useRoute } from "context/RouterProvider";

// sections
import List from "./Sections/List/List";
import Insert from "./Sections/Insert/Insert";
import Block from "./Sections/Block/Block";
import Delete from "./Sections/Delete/Delete";
import Modify from "./Sections/Modify/Modify";

const Main = () => {
  const { languageState } = useLanguage();
  const { setRouteState } = useRoute();

  const [tab, setTab] = useState(0);

  const handleChange = (e, newTab) => {
    setTab(newTab);
  };

  const navigateToInsert = () => {
    setTab(1);
  };

  const tabs = [
    <List navigateToInsert={navigateToInsert} />,
    <Insert />,
    <Modify navigateToInsert={navigateToInsert} />,
    <Block />,
    <Delete />,
  ];

  useEffect(() => {
    setRouteState({
      type: "set",
      to: 2,
      page: languageState.texts.Dashboard.Sidebar.Links[3].label,
    });
    scrollTo();
  }, []);

  return (
    <main className="dashboard-content">
      <SitoContainer ignoreDefault className="dashboard-container-fluid dashboard-p-0">
        <h1 className="dashboard-h3 dashboard-mb-3">
          {languageState.texts.Dashboard.User.Title.light}{" "}
          <strong>{languageState.texts.Dashboard.User.Title.strong}</strong>
        </h1>

        <SitoContainer ignoreDefault className="dashboard-row">
          <SitoContainer ignoreDefault className="dashboard-w-100 dashboard-d-flex">
            <SitoContainer ignoreDefault className="dashboard-w-100 dashboard-card">
              <TabView
                value={tab}
                onChange={handleChange}
                tabs={languageState.texts.Dashboard.User.Tabs}
                content={tabs}
              />
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </SitoContainer>
    </main>
  );
};

export default Main;
