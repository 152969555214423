/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

// @mui icons
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

const Error = (props) => {
  const { onAction } = props;
  const { languageState } = useLanguage();
  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}
      flexDirection="column"
    >
      <ErrorOutlineIcon color="error" sx={{ fontSize: "4rem" }} />
      <h4 className="dashboard-h3 dashboard-mb-3">{languageState.texts.Dashboard.Message.Error}</h4>
      {onAction && (
        <button type="submit" className="butn" onClick={onAction}>
          {languageState.texts.Dashboard.Submit.Retry}
        </button>
      )}
    </SitoContainer>
  );
};

Error.defaultProps = {
  onAction: undefined,
};

Error.propTypes = {
  onAction: PropTypes.func,
};

export default Error;
