/* eslint-disable react/function-component-definition */

import { useEffect } from "react";

// @mui components
import { Box } from "@mui/material";

// utils
import { scrollTo } from "utils/functions";

const About = () => {
  useEffect(() => {
    scrollTo();
  }, []);

  return <Box>Hola</Box>;
};

export default About;
