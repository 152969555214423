import { BrowserRouter, Routes, Route } from "react-router-dom";

// @mui
import { ThemeProvider, CssBaseline } from "@mui/material";

import dark from "assets/theme/dark";

// layouts
import View from "layouts/View/View";
import DashboardView from "layouts/Dashboard/Dashboard";

// views
import Home from "views/Home/Home";
import Login from "views/Login/Login";
import Recovery from "views/Login/Recovery";
import EmailValidation from "views/Login/EmailValidation";
import Register from "views/Register/Register";
import Contact from "views/Contact/Contact";
import NotFound from "views/NotFound/NotFound";
import About from "views/About/About";
import Logout from "views/Login/Logout";
// dashboard views
import Dashboard from "views/Dashboard/Dashboard";
import User from "views/Dashboard/User/User";
import Province from "views/Dashboard/Province/Province";
import Me from "views/Profile/Me";

function App() {
  return (
    <ThemeProvider theme={dark}>
      <CssBaseline />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<View />}>
            <Route index element={<Home />} />
            <Route exact path="/about-us" element={<About />} />
            <Route exact path="/contact-us" element={<Contact />} />
          </Route>
          <Route path="/profile" element={<View />}>
            <Route index element={<Me />} />
          </Route>
          <Route path="/login" element={<View />}>
            <Route index element={<Login />} />
            <Route exact path="/login/recovery" element={<Recovery />} />
            <Route exact path="/login/email-validation" element={<EmailValidation />} />
            <Route exact path="/login/register" element={<Register />} />
          </Route>
          <Route path="/dashboard" element={<DashboardView />}>
            <Route index element={<Dashboard />} />
            <Route exact path="/dashboard/users" element={<User />} />
            <Route exact path="/dashboard/provinces" element={<Province />} />
            <Route exact path="/dashboard/log-out" element={<Logout />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
