/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

// sito components
import SitoContainer from "sito-container";

// own components
import Loader from "components/Loader/Loader";

// layouts
import Section from "layouts/Section/Section";
import Hero from "layouts/Hero/Hero";

// context
import { useRoute } from "context/RouterProvider";
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

// utils
import {
  userLogged,
  scrollTo,
  isAdmin,
  passwordValidation,
  createRecoveringCookie,
  removeRecoveringCookie,
} from "utils/functions";

// services
import { passwordRecovery, modifyUser } from "services/post";

const Recovery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, getValues } = useForm();
  const { setRouteState } = useRoute();
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [loading, setLoading] = useState(false);
  const [recovering, setRecovering] = useState(false);
  const [userData, setUserData] = useState({});

  const [ok, setOk] = useState(1);

  const localRecovery = async (d) => {
    setLoading(true);
    const { email } = d;
    try {
      const response = await passwordRecovery(email);
      if (response.status === 200) {
        const data = await response.data;
        if (data.data === null)
          setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.SomeWrong,
          });
        else {
          createRecoveringCookie(email);
          setNotificationState({
            type: "set",
            ntype: "success",
            message: languageState.texts.Messages.EmailForRecovery,
          });
        }
      } else {
        setLoading(false);
        const { error } = response;
        let message;
        if (error.indexOf("422") > -1) message = languageState.texts.Errors.Wrong;
        else if (error.indexOf("Error: Network Error") > -1)
          message = languageState.texts.Errors.NotConnected;
        else message = languageState.texts.Errors.SomeWrong;
        setNotificationState({
          type: "set",
          ntype: "error",
          message,
        });
      }
    } catch (err) {
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.SomeWrong,
      });
    }
    setLoading(false);
  };

  const localModifyUser = async (d) => {
    setLoading(true);
    const { password, rpassword } = d;
    if (password === rpassword) {
      try {
        const response = await modifyUser(userData.id, {
          password_hash: password,
          password_hash_repeat: rpassword,
        });
        if (response.status === 200) {
          const data = await response.data;
          if (data.data === null)
            setNotificationState({
              type: "set",
              ntype: "error",
              message: languageState.texts.Errors.SomeWrong,
            });
          else {
            setNotificationState({
              type: "set",
              ntype: "success",
              message: languageState.texts.Messages.PasswordResetSuccessful,
            });
            navigate("/login");
          }
        } else {
          setLoading(false);
          const { error } = response;
          let message;
          if (error.indexOf("422") > -1) message = languageState.texts.Errors.Wrong;
          else if (error.indexOf("Error: Network Error") > -1)
            message = languageState.texts.Errors.NotConnected;
          else message = languageState.texts.Errors.SomeWrong;
          setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
      } catch (err) {
        setNotificationState({
          type: "set",
          ntype: "error",
          message: languageState.texts.Errors.SomeWrong,
        });
      }
    } else
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.DifferentPassword,
      });

    setLoading(false);
  };

  useEffect(() => {
    const queryParams = location.search.substring(1);
    const [param, value] = queryParams.split("=");
    if (param === "user" && value) {
      setRecovering(true);
      setUserData({ id: value });
      removeRecoveringCookie();
    }
    if (userLogged()) {
      if (isAdmin()) navigate("/dashboard");
      else navigate("/me");
    } else {
      const loginForm = document.getElementById("login-form");
      setRouteState({ type: "set", to: 2, page: languageState.texts.Links[2].label });
      scrollTo((loginForm.offsetHeight - loginForm.offsetTop) / 2);
    }
  }, []);

  const validate = () => {
    setOk(true);
  };

  const invalidate = (e) => {
    e.preventDefault();
    if (ok) {
      const { id } = e.target;
      const { password, rpassword } = getValues();
      e.target.focus();
      let message;
      setOk(false);
      switch (id) {
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            userData.nombre
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.EmailRequired,
          });
      }
    }
  };

  return (
    <>
      <Loader visible={loading} />
      <Hero>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-md-12">
              <h1>{languageState.texts.Links[2].label}</h1>
            </SitoContainer>
            <SitoContainer ignoreDefault className="col-md-12">
              <ul>
                <li>
                  <Link to="/">{languageState.texts.Links[0].label}</Link>
                </li>
                <li>
                  <Link to="/login">{languageState.texts.Links[2].label}</Link>
                </li>
                <li>
                  <Link to="/login/recovery" disabled>
                    {languageState.texts.SignIn.titleRecovery}
                  </Link>
                </li>
              </ul>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Hero>
      <Section>
        <SitoContainer ignoreDefault className="container">
          <SitoContainer ignoreDefault className="row">
            <SitoContainer ignoreDefault className="col-lg-7 center-col">
              <SitoContainer
                ignoreDefault
                className="theme-shadow padding-40px-all xs-padding-25px-all border-radius-3"
              >
                {!recovering && (
                  <h3 className="text-center font-size30">
                    {languageState.texts.SignIn.titleRecovery}
                  </h3>
                )}
                <form
                  id="login-form"
                  method="post"
                  action="#!"
                  onSubmit={handleSubmit(!recovering ? localRecovery : localModifyUser)}
                >
                  <h4 className="text-center dashboard-h3">
                    {languageState.texts.SignIn.recovering}
                  </h4>
                  <SitoContainer ignoreDefault className="row" sx={{ marginTop: "20px" }}>
                    {languageState.texts.SignIn.inputs
                      .filter((item) => {
                        if (item.recovery && !recovering) return item;
                        if (item.recoveryRequest && recovering) return item;
                        return null;
                      })
                      .map((item) => (
                        <SitoContainer key={item.id} ignoreDefault className="col-md-12">
                          <SitoContainer ignoreDefault className="form-group">
                            <label htmlFor={item.id}>{item.label}</label>
                            <input
                              placeholder={item.placeholder}
                              type={item.type}
                              name={item.id}
                              id={item.id}
                              required={item.required}
                              onInput={validate}
                              onInvalid={invalidate}
                              {...register(item.id)}
                            />
                          </SitoContainer>
                        </SitoContainer>
                      ))}
                  </SitoContainer>

                  <SitoContainer ignoreDefault className="row align-items-center margin-30px-top">
                    <SitoContainer ignoreDefault className="col-md-6">
                      <button type="submit" className="butn">
                        {languageState.texts.SignIn.buttons.send}
                      </button>
                    </SitoContainer>
                  </SitoContainer>
                </form>
              </SitoContainer>
            </SitoContainer>
          </SitoContainer>
        </SitoContainer>
      </Section>
    </>
  );
};

export default Recovery;
