/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { provinceList } from "services/get";
import { deleteProvince } from "services/post";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

const Delete = () => {
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [localList, setLocalList] = useState({});
  const [loading, setLoading] = useState(true);

  const [provinceToDelete, setProvinceToDelete] = useState(0);

  const changeProvinceToDelete = (e) => setProvinceToDelete(Number(e.target.value));

  const fetch = async () => {
    setLoading(true);
    const response = await provinceList();
    if (response.status === 200) {
      const { data } = await response.data;
      if (data.length) setLocalList(data);
    } else {
      setLocalList(-1);
      setNotificationState({
        type: "set",
        message: languageState.texts.Errors.NotConnected,
        ntype: "error",
      });
    }
    setLoading(false);
  };

  const retry = () => {
    fetch();
  };

  const localDeleteUser = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await deleteProvince(provinceToDelete);
      if (response.status === 200) {
        setNotificationState({
          type: "set",
          message: languageState.texts.Messages.ProvinceDeletedSuccessful,
          ntype: "success",
        });
        retry();
      }
    } catch (err) {
      const { message } = err;
      if (message.indexOf("401") > -1) {
        setNotificationState({
          type: "set",
          message: languageState.texts.Errors.InvalidProvince,
          ntype: "error",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <SitoContainer
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {!loading && localList.length > 0 && (
        <form onSubmit={localDeleteUser} className={css({ width: "100%" })}>
          <SitoContainer ignoreDefault className="form-group" sx={{ width: "100%" }}>
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.Province.Delete.Title}
            </h3>
            <label htmlFor="users">{languageState.texts.Dashboard.Province.Delete.Label}</label>
            <select
              className="form-control"
              id="users"
              value={provinceToDelete}
              onChange={changeProvinceToDelete}
            >
              {localList.map((jtem) => (
                <option key={jtem.id} value={jtem.id}>
                  {jtem.nombre}
                </option>
              ))}
            </select>
            <SitoContainer ignoreDefault sx={{ marginTop: "20px" }}>
              <button type="submit" className="butn">
                {languageState.texts.Dashboard.Submit.Delete}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
      {!loading && !localList.length && localList !== -1 && <Empty />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

export default Delete;
