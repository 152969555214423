/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// @emotion
import { css } from "@emotion/css";

// sito components
import SitoContainer from "sito-container";

// own components
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { userList, getMe, getAgents } from "services/get";
import { modifyUser } from "services/post";

// functions
import { passwordValidation, isAdmin, getIndexOf } from "utils/functions";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

const Modify = (props) => {
  const { navigateToInsert } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const { register, handleSubmit, getValues, setValue } = useForm();

  const [localList, setLocalList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [ok, setOk] = useState(false);
  const [invalids, setInvalids] = useState({});

  const [userToModify, setUserToModify] = useState(0);

  const changeUserToModify = (e) => setUserToModify(Number(e.target.value));

  const fetch = async () => {
    setLoading(true);
    let response;
    if (isAdmin()) response = await userList();
    else response = await getAgents();
    if (response.status === 200) {
      const { data } = await response.data;
      if (data.length) {
        setAllData(data);
        const element = getIndexOf(
          "id",
          userToModify === 0 ? userToModify + 1 : userToModify,
          data
        );
        // reading default values
        // name - nombre
        if (data[element].nombre) setValue("name", data[element].nombre);
        // lastname - apellidos
        if (data[element].apellidos) setValue("lastname", data[element].apellidos);
        // email
        if (data[element].email) setValue("email", data[element].email);
        let parsedUsers = data;
        if (!isAdmin()) {
          const { inmobiliaria_id } = await getMe();
          parsedUsers = [];
          data.forEach((item) => {
            if (inmobiliaria_id === item.inmobiliaria_id) parsedUsers.push(item);
          });
        }
        setLocalList(parsedUsers);
      }
    } else {
      setLocalList(-1);
      setNotificationState({
        type: "set",
        message: languageState.texts.Errors.NotConnected,
        ntype: "error",
      });
    }
    setLoading(false);
  };

  const retry = () => {
    fetch();
  };

  const localModifyUser = async (d) => {
    setLoading(true);
    const { password, rpassword } = d;
    if (password === rpassword) {
      try {
        const { name, lastname, email } = d;
        const data = {
          nombre: name,
          apellidos: lastname,
          email,
        };
        if (password.length > 0 && rpassword.length > 0) {
          data.password_hash = password;
          data.password_hash_repeat = rpassword;
        }
        const response = await modifyUser(userToModify, data);
        if (response.status === 200) {
          setNotificationState({
            type: "set",
            message: languageState.texts.Messages.UserModifiedSuccessful,
            ntype: "success",
          });
          retry();
        }
      } catch (err) {
        if (err.message.indexOf("422") > -1) {
          const error = err.response.data.data;
          const newInvalids = invalids;
          error.forEach((item, i) => {
            newInvalids[item.field] = true;
            if (i === 0) document.getElementById(item.field).focus();
          });
          setInvalids(newInvalids);
          let message = languageState.texts.Errors.SomeWrong;
          if (error.length === 1) message = languageState.texts.Errors.ServerErrors[error[0].field];
          setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
      }
    } else
      setNotificationState({
        type: "set",
        ntype: "error",
        message: languageState.texts.Errors.DifferentPassword,
      });
    setLoading(false);
  };

  const validate = () => {
    setOk(true);
  };

  // eslint-disable-next-line consistent-return
  const invalidate = (e) => {
    setOk(true);
    e.preventDefault();
    const { user, email, password, rpassword } = getValues();
    if (ok) {
      let message;
      const { id } = e.target;
      e.target.focus();
      setOk(false);
      switch (id) {
        case "tuition":
        case "email":
          message = languageState.texts.Errors.InvalidEmail;
          if (email.length === 0) message = languageState.texts.Errors.EmailRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "user":
          message = languageState.texts.Errors.NameRequired;
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        case "rpassword":
        case "password": {
          const passwordValidationResult = passwordValidation(
            id === "password" ? password : rpassword,
            user
          );
          switch (passwordValidationResult) {
            case 0:
              message = languageState.texts.Errors.PasswordLengthValidation;
              break;
            case 1:
              message = languageState.texts.Errors.PasswordCharacterValidation;
              break;
            default:
              message = languageState.texts.Errors.PasswordNameValidation;
              break;
          }
          return setNotificationState({
            type: "set",
            ntype: "error",
            message,
          });
        }
        default:
          return setNotificationState({
            type: "set",
            ntype: "error",
            message: languageState.texts.Errors.TermsNdConditionRequired,
          });
      }
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (allData.length) {
      const element = getIndexOf("id", userToModify, allData);
      // name - nombre
      if (allData[element].nombre) setValue("name", allData[element].nombre);
      // lastname - apellidos
      if (allData[element].apellidos) setValue("lastname", allData[element].apellidos);
      // email
      if (allData[element].email) setValue("email", allData[element].email);
    }
  }, [userToModify]);

  return (
    <SitoContainer
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {!loading && localList.length > 0 && (
        <form onSubmit={handleSubmit(localModifyUser)} className={css({ width: "100%" })}>
          <SitoContainer ignoreDefault className="form-group" sx={{ width: "100%" }}>
            <h3 className="dashboard-h3 dashboard-mb-3">
              {languageState.texts.Dashboard.User.Modify.Title}
            </h3>
            <label htmlFor="users">{languageState.texts.Dashboard.User.Modify.Label}</label>
            <select
              className="form-control"
              id="users"
              value={userToModify}
              onChange={changeUserToModify}
            >
              {localList.map((jtem) => (
                <option key={jtem.id} value={jtem.id}>
                  {jtem.nombre}
                </option>
              ))}
            </select>
            {languageState.texts.SignIn.inputs.map((item, i) => (
              <SitoContainer ignoreDefault key={item.id} sx={{ marginTop: i === 0 ? "20px" : 0 }}>
                {item.modify && (
                  <SitoContainer ignoreDefault className="form-group">
                    <label htmlFor={item.id}>{item.label}</label>
                    <input
                      placeholder={item.placeholder}
                      type={item.type}
                      name={item.id}
                      id={item.id}
                      maxLength={item.maxLength}
                      onInput={validate}
                      onInvalid={invalidate}
                      {...register(item.id)}
                      className={invalids[item.id] ? "error" : ""}
                    />
                  </SitoContainer>
                )}
              </SitoContainer>
            ))}
            <SitoContainer ignoreDefault sx={{ marginTop: "20px" }}>
              <button type="submit" className="butn">
                {languageState.texts.Dashboard.Submit.Save}
              </button>
            </SitoContainer>
          </SitoContainer>
        </form>
      )}
      {!loading && !localList.length && localList !== -1 && <Empty onAction={navigateToInsert} />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

Modify.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
};

export default Modify;
