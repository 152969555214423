/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from "prop-types";

// framer-motion
import { motion } from "framer-motion";

// @emotion
import { css } from "@emotion/css";

const Section = (props) => {
  const { className, children, background, extraProps } = props;

  const dynamicCss = css({
    background: background.indexOf("/") > -1 ? `url(${background})` : background,
  });

  const apparition = {
    hidden: { opacity: 1, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <motion.div
      variants={apparition}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <section className={`${className} ${dynamicCss}`} {...extraProps}>
        {children}
      </section>
    </motion.div>
  );
};

Section.defaultProps = {
  className: "",
  children: <></>,
  background: "",
  extraProps: {},
};

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  background: PropTypes.string,
  extraProps: PropTypes.object,
};

export default Section;
