/* eslint-disable camelcase */
/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";

// sito components
import SitoContainer from "sito-container";

// own components
import DataTable from "components/MUI/DataTable/DataTable";
import Empty from "components/MUI/Empty/Empty";
import Error from "components/MUI/Error/Error";
import Loader from "components/Loader/Loader";

// services
import { provinceList } from "services/get";

// contexts
import { useLanguage } from "context/LanguageProvider";
import { useNotification } from "context/NotificationProvider";

const List = (props) => {
  const { navigateToInsert } = props;
  const { languageState } = useLanguage();
  const { setNotificationState } = useNotification();

  const [localList, setLocalList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
    setLoading(true);
    const response = await provinceList();
    if (response.status === 200) {
      const { data } = await response.data;
      if (data.length) {
        const parsedColumns = [];
        Object.keys(data[0]).forEach((item) => {
          if (item === "id") parsedColumns.push({ field: item, width: 90 });
          else
            parsedColumns.push({
              field: item,
              headerName: languageState.texts.Dashboard.TableAttributes[item],
              width: 200,
              editable: false,
            });
        });
        setColumns(parsedColumns);
        setLocalList(data);
      }
    } else {
      setLocalList(-1);
      setNotificationState({
        type: "set",
        message: languageState.texts.Errors.NotConnected,
        ntype: "error",
      });
    }
    setLoading(false);
  };

  const retry = () => {
    fetch();
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ position: "relative", minHeight: "300px" }}
    >
      <Loader visible={loading} minimal />
      {!loading && localList.length > 0 && <DataTable columns={columns} rows={localList} />}
      {!loading && !localList.length && localList !== -1 && <Empty onAction={navigateToInsert} />}
      {!loading && localList === -1 && <Error onAction={retry} />}
    </SitoContainer>
  );
};

List.propTypes = {
  navigateToInsert: PropTypes.func.isRequired,
};

export default List;
