/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

const SideHeader = (props) => {
  const { text } = props;

  return <li className="dashboard-sidebar-header">{text}</li>;
};

SideHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SideHeader;
