/* eslint-disable react/function-component-definition */
import PropTypes from "prop-types";

// @mui icons
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

// sito components
import SitoContainer from "sito-container";

// contexts
import { useLanguage } from "context/LanguageProvider";

const Empty = (props) => {
  const { onAction } = props;
  const { languageState } = useLanguage();
  return (
    <SitoContainer
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}
      flexDirection="column"
    >
      <ReceiptLongIcon color="secondary" sx={{ fontSize: "4rem" }} />
      <h4 className="dashboard-h3 dashboard-mb-3">
        {languageState.texts.Dashboard.Message.NoData}
      </h4>
      {onAction && (
        <button type="submit" className="butn" onClick={onAction}>
          {languageState.texts.Dashboard.Submit.Insert}
        </button>
      )}
    </SitoContainer>
  );
};

Empty.defaultProps = {
  onAction: undefined,
};

Empty.propTypes = {
  onAction: PropTypes.func,
};

export default Empty;
